/* Base Styles */
body {
  font-family: 'Manrope' sans-serif;
  font-weight: 600;
  margin: 0;
  padding: 0;
  background-color: #fcf6f6; 
  line-height: 1.6; 
}
html {
  scroll-behavior: smooth;
}

/* loading screen */

.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  text-align: center;
}

.loading-screen h2 {
  font-family: Arial, sans-serif;
  color: #333;
  margin-bottom: 20px;
}

.loading-animations {
  display: flex;
  gap: 20px;
}


/* General Header Styling */




/* Header Styles */
header {
  left: 0;
  max-height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: transparent;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0);
  position:sticky;
  top: 0;
  z-index: 50;
  transition: all 0.2s ease-in-out;
}

/* Scrolled Header */
header.scrolled {
  background-color: white;
  color: black;
}

/* Logo and Brand Name */
header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

header .logo img {
  width: 80px;
  height: 50px;
  object-fit: cover;
  border-radius: 20px;
}

header.scrolled .logo img {
  filter: invert(1);
}

header .logo h2 {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  
  color: white;
  font-family: 'Montserrat', sans-serif;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

header.scrolled .logo h2 {
  
  color: inherit;
  background: url("../public/images/background_color.jpg") no-repeat center center / cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Navigation Links */
header nav {
  display: none; /* Hidden by default for mobile */
  margin-left: auto;
  margin-right: 1%;
  flex-direction: column;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 1rem;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 40;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.2s ease;
}

header nav.open {
  display: flex;
  opacity: 1;
  transform: translateY(0);
}

header nav a {
  color: black;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

header nav a:hover {
  color: #f7f7f9;
  font-size:1rem;
  transition: color 0.2s ease;
  cursor: pointer;
  text-shadow: 2px 2px 5px rgba(243, 239, 239, 0.5);
  -webkit-text-stroke: 0.41px rgb(253, 252, 252); 

}

/* Desktop Navigation */
@media (min-width: 900px) {
  header nav {
    display: flex;
    position: static;
    flex-direction: row;
    background: none;
    padding: 0;
    box-shadow: none;
    gap: 2.5rem;
    opacity: 1;
    transform: translateY(0);
  }

  header nav a {
    color: inherit;
  }
}

/* Contact Button */
header .contact-button a {
  text-decoration: none;
  padding: 0.5rem 1.2rem;
  background-color: #f8f8f7;
  color: #0b0a0a;
  border: 2px solid #2d1fae;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s ease;
}

header.scrolled .contact-button a {
  background-color: #f5f5f8;
  color: rgb(102, 65, 65);
  border-color: rgb(102, 65, 65);
}

header .contact-button button:hover {
  background-color: #f5f5f8;
  color: #d97706;
  border-color: #d97706;
}

/* New Hamburger Design */
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center-align within its container */
  gap: 5px;
  cursor: pointer;
  z-index: 60;
  position: absolute; /* Position the hamburger */
  top: 20px; /* Adjust top margin */
  right: 20px; /* Position it in the right corner */
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

header.scrolled .hamburger .line {
  background-color: black;
}

.hamburger.open .line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Hide Hamburger on desktop (above 900px) */
@media (min-width: 900px) {
  .hamburger {
    display: none; /* Hide hamburger on larger screens */
  }
}

/* Mobile Styles */
@media (max-width: 899px) {
  header nav {
    display: none;
  }
  header .logo h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }
  header nav.open {
    display: flex;
  }

  .hamburger {
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  header .contact-button a {
    display: none;
  }
}


.hero {
  /* position: relative; */
  color: rgb(246, 240, 240);
  padding: 40px 10px;
  text-align: center;
  overflow: hidden;
}

.hero-title {
  display: flex;
  flex-direction: column; /* Stack h1 and h2 vertically */
  justify-content: center;
  text-align: left; /* Align text to the left */
  width: 100%; /* Ensure it takes full width */
  align-items: center; /* Center horizontally */
line-height: 20px;
padding-top: 5%;
}


.hero-content h1 {
  font-size: 4rem;
   /* Add space between h1 and h2 */
}

.hero-content h2 {
  margin-top:10;
  font-size: 4.5rem;
  text-shadow: 2px 3px 10px rgba(255, 255, 255, 0.802);
  -webkit-text-stroke: 0.41px rgb(253, 252, 252); 
  color: #f9f8fc;
}
.hero-content p{
  font-size: 1.9rem;
 
  text-shadow: 2px 2px 5px rgba(23, 22, 22, 0.5);
}
.hero-content {
  position: relative;
  z-index: 1; /* Above the video */
  text-align: center; /* Center the content inside this container */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
}

/* .background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; Behind content
} */

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}


svg {
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  z-index: -2;
  pointer-events: none; /* Make it non-interactive */
}

/* Mobile Styles */
@media (max-width: 899px){
  .hero-content p {
    font-size: 1.4rem;
   
  }
  .hero-content h2 {
    font-size: 2.5rem;
  
  }
  
  .hero-title {
    line-height: 50px;
    text-align: center;
    
  }
.hero-content h1 {
  font-size: 2rem;
  margin-bottom: 0px; /* Add space between h1 and h2 */
}

}

.cta-button  {
  margin-top: 20px;
  width: auto;
  display: inline-block; /* Ensures the button behaves like a block element */
  padding: 12px 24px; /* Adds padding */
  background-color: #fefdff; /* Button background color */
  color: #160138; /* Button text color */
  font-size: 1rem; /* Button font size */
  font-weight: bold; /* Bold button text */
  border: none; /* No border */
  border-radius: 25px; /* Rounded corners */
  cursor: pointer; /* Pointer on hover */
  text-align: center; /* Centers text */
  text-decoration: none; /* No underline */
  transition: background-color 0.2s ease, transform 0.2s ease; /* Smooth transitions */
}

/* Hover effect for CTA button */
.cta-button:hover {
  background-color: #5a14cb; /* Darker purple on hover */
  /* text-shadow: 2px 2px 5px rgba(23, 22, 22, 0.5); */
  transform: scale(1.05); /* Slightly enlarges the button */
color: #fcf9f9;
box-shadow: 0 8px 16px 0 rgba(247, 193, 247, 0.718), 0 6px 20px 0 rgba(251, 251, 251, 0.353);
}

/* Active state */
.cta-button:active {
  transform: scale(0.95); /* Shrinks the button slightly when pressed */
}


/* Our Approach Section */
.our-approach {
  background-color: rgba(249, 244, 244, 0.968); /* Light background color */
  padding: 10px 20px; /* Padding for spacing */
  display: flex; /* Flex layout for side-by-side alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center content */
  gap: 40px; /* Space between title and paragraph */
  max-width: 100%; /* Limit width for readability */
  margin: 0 auto; /* Center align section */
   /* Align text to the left */
  border-bottom: 2px solid #ddd;
  z-index: 2;
}

/* Our Approach Heading */
.our-approach h2 {
  text-align: center;
  font-size: 2.5rem; /* Large font size */
  color: #333; /* Dark color */
  font-weight: bold; /* Bold font */
  text-transform: uppercase; /* Uppercase letters */
  letter-spacing: 1.2px; /* Letter spacing */
  flex: 1; /* Take up available space */
}

/* Our Approach Paragraph */
.our-approach p {
  font-size: 1.3rem; /* Standard font size */
  color: #666; /* Gray text color */
  line-height: 1.6; /* Increase line height for readability */
  flex: 2; /* Wider than the title */
}

@media (min-width: 780px){
  .hero-title {
    line-height: 3rem;
    text-align: center;
    
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {





  .our-approach {
    flex-direction: column; /* Stack elements vertically */
    text-align: center; /* Center text on mobile */
    gap: 20px; /* Reduced gap for mobile */
  }
  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 10px; /* Add space between h1 and h2 */
  }
  
  .our-approach h2,
  .our-approach p {
    flex: unset; /* Remove flex-grow for stacking */
    width: 100%; /* Optional: Ensure full width for better alignment */
  }

  .cta-button{
    margin-top: 20px;
    display: inline-block; /* Ensures the button behaves like a block element */
    padding: 10px 20px; /* Adds padding */
    background-color: #f2f0ed; /* Button background color */
    color: #573a3a; /* Button text color */
    font-size: 1rem; /* Button font size */
   
  }

 }

/* Services Section */
/* Container for our services */
.our_services {
  text-align: center; /* Center align the heading */
  padding: 20px 0; /* Add top and bottom padding */
}

/* Styling for H2 */
.our_services h2 {
  font-size: 2.5rem; /* Large font size */
  color: #333; /* Dark text color */
  text-transform: uppercase; /* Make text uppercase */
  letter-spacing: 1.5px; /* Add spacing between letters */
  font-weight: bold; /* Make the text bold */
  margin-bottom: 30px; /* Space below the heading */
  
  /* Add underline effect */
  padding-bottom: 10px; /* Padding below the text */
}

.services {
  justify-content: center; /* Center the service cards */
  display: flex; /* Use flexbox for side-by-side alignment */
  gap: 8px; /* Small gap between cards */
  padding-left: 15%; /* Add left padding */
  padding-right: 15%; /* Add right padding */
}

/* Service Card Styles */
.service-card {
  position: relative; /* Position for absolute child elements */
  height: 450px; /* Fixed height */
  width: 200px; /* Initial width for larger screens */
  overflow: hidden; /* Hide overflowing content */
  border-radius: 50px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6); /* Shadow for card */
  transition: width 0.5s ease-in-out, transform 0.5s ease-in-out; /* Smooth width and transform transition */
  cursor: pointer; /* Pointer cursor */
  display: flex;
  flex-direction: column; /* Stack title vertically */
  justify-content: flex-end; /* Start from bottom */
}

/* Image Style */
.service-image {
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  object-fit: cover; /* Cover image */
  transition: opacity 0.3s; /* Fade effect */
}

/* Service Content */
.service-info {
  position: absolute;
  bottom: 0; /* Position at the bottom */
  left: 0;
  right: 0;
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.3); /* Dark overlay for readability */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease; /* Transition for hover effect */
}

/* Service Title */
.service-title {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1); /* Dark shadow */
  margin: 0; /* No margin */
  transform: rotate(270deg); /* Rotate title */
  white-space: nowrap; /* Prevent wrapping */
  opacity: 1; /* Title should always be visible */
  color: rgb(254, 250, 250); /* Title color */
  font-size: 200%; /* Increase font size to fill the card */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100%; /* Full height of the card */
  transition: opacity 0.3s ease-in; /* Smooth fade-in */
}

/* Service Description */
.service-description {
 
  opacity: 0; /* Initially hidden */
  font-size: 1.2rem;
  text-align: left;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  position: absolute;
  z-index: 1;
  transition: opacity 0.3s ease-in; /* Smooth fade-in and fade-out transition */
  transition-delay: 0s; /* No delay for vanishing */
}

/* Default style: Hide hover-title by default */

/* Desktop Styles */
@media (min-width: 900px) {

 
  .service-card:hover {
    width: 500px; /* Expanded width for larger screens */
    transform: scale(1.05); /* Scale up the card on hover */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3); /* Increase shadow for depth */
  }

  .service-card:hover .service-title{
    opacity: 0;
    transition: opacity 0.3s ease-in; 
  
  }
/* Remove transition on mouse leave to make title reappear instantly */
.service-card:not(:hover) .service-title {
    transition: none; /* No transition for instant reappearance */
}
  .service-card:hover .service-description {
    left: 0;
    opacity: 1; /* Show description on hover */
    transition: opacity 0.3s ease-in;
    transition-delay: 0s; /* Smooth transition for description visibility */
  }
  .service-card:not(:hover) .service-description {
    transition: none; /* No transition for instant vanish */
}
  .service-card:hover .service-title {
    opacity: 0; /* Hide the title when hovered */
  }

  /* Ensure service-info is visible on hover */
  .service-card:hover .service-info {
    background: url("../public/images/background_color.jpg") no-repeat center center / cover;
    background-color: rgb(50, 48, 47); /* Dark overlay for readability */
  }

  /* Service Description: Ensure description is visible when hovered */
  .service-card:hover .service-description {
    opacity: 1;
    transition-delay: 0.2s;
  }

  /* Remove hover-title by default, only show on hover */
  .service-card:hover .hover-title {
    opacity: 1; /* Show hover-title when hovered */
  }
  .service-card:hover .service-description li {
    opacity: 1; /* Make list items visible */
  }
  .service-card:hover .service-description ul{
    opacity: 1;
  }
  .service-description ul {
    
    list-style-type: disc; /* Ensure bullet points */
    padding-left: 25px; /* Space between bullet and text */
    margin: 0; /* Remove default margin */
  }
  
  .service-description li {
   
    font-size: 1.5rem; /* Font size for list items */
    color: white; /* Text color */
    margin-bottom: 5px; /* Space between items */
  }
  .service-card .hover-title {
    opacity: 0; /* Hidden initially */
    color: rgb(254, 250, 250);
    font-size: 1.7rem;
    display: block;
    height: 100%;
    position: absolute;
    top: 40px; /* Position at top */
    transition: opacity 0.3s ease;
    z-index: 3; /* Ensure it's above description */
  }
}

/* Mobile Responsive Styles */
@media (max-width: 900px) {
  .services {
    flex-direction: column; /* Stack cards vertically */
    gap: 16px;
  }

  .service-card {
    width: 100%; /* Full width on mobile */
    max-height: 100px; /* Collapsed state on mobile */
    margin: 0 auto; /* Center the card */
    transition: max-height 0.3s ease-in-out; /* Smooth transition for expanding */
    cursor: pointer; /* Pointer cursor for touch interaction */
    position: relative; /* For absolute positioning of description and hover-title */
  }
  
  /* Expanded state on click */
  .service-card.expanded {
    min-height:300px; /* Expanded state height */
    background: url("../public/images/background_color.jpg") no-repeat center center / cover; /* Background on expanded */
  }

  .service-title {
    transform: rotate(0deg);
    font-size: 1.2rem; /* Adjust title size for mobile */
    text-align: center;
    color: rgb(254, 250, 250); /* Title color */
    margin: 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 1); /* Title shadow for readability */
  }

 

  /* Show description only when the card is expanded on mobile */
  .service-card.expanded .service-description {
    opacity: 1;
    
  }
  .service-card.expanded .service-description:hover {
    opacity: 1;
    
  }
  .service-card.expanded .service-title {
    display: none; /* Hide title when expanded */
  }

  /* Service Info: Always visible when card is expanded */
  .service-info {
    opacity: 1; /* Always visible on mobile */
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay for readability */
  }

  .service-card.expanded .service-info .hover-title ,.hover-title {
    display: none; /* Remove hover-title by default */
  }

  /* Position hover-title above description when expanded */
  .service-card.expanded .hover-title {
    display: block; /* Show hover-title when expanded */
    position: absolute;
    top: 10px; /* Position it above the description */
    
    color: white; /* Title color */
    font-size: 1.5rem; /* Adjust size for better visibility */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 1); /* Title shadow for readability */
  }

  /* Remove hover effects completely on mobile */



  /* Remove hover effects on service-info */
  .service-card:hover .service-info {
      background-color: rgba(0, 0, 0, 0.5); /* Maintain the background on hover */
  }

  /* Remove hover effects on image */
  .service-card:hover .service-image {
    opacity: 1; /* Maintain full opacity */
  }

  /* Hide service-info on hover for mobile */
  .service-card:hover .service-info {
    opacity: 1; /* Ensure service-info is hidden on hover */
  }

  .service-description ul {
    list-style-type: disc; /* Ensure bullet points */
    padding-left: 20px; /* Space between bullet and text */
    margin: 0; /* Remove default margin */
  }
  
  .service-description li {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

    font-size: 1rem; /* Font size for list items */
    color: rgb(255, 255, 255); /* Text color */
    margin-bottom: 5px; /* Space between items */
  }

  
}




/* Centering the box */
.box-wrapper {
  display: flex;
  justify-content: center;
  padding: 4rem 0;
}

/* Main Card */
.call-to-action-card {
  background: url('../public/images/cool-background-2.png') no-repeat center center/cover; /* purple */
  color: #fff;
  border-radius: 25px;
  padding: 5%;
  min-height: 300px;
  max-width: 60%;
  width: 100%;
}

/* Content layout */
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  background: rgba(0, 0, 0, 0.2); /* Increased opacity for better contrast */

}

.left-content {
  max-width: 40%;
  padding: 2rem;
  
}

.right-content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}

/* Heading and Paragraph */
.heading {
  font-size: 3rem; /* 80% of the left content */
  font-weight: bold;
  margin-bottom: 1rem;
}

.paragraph {
  font-size: 1.2rem;
  text-align: justify;
}

/* Form Elements */
.form-group {
  margin-bottom: 1rem;
  max-width: 100%;
}

.form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: medium;
  margin-bottom: 0.5rem;
}

.input-group {
  display: flex;
  align-items: center;
  border: 1px solid #6b7280; /* gray border */
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #fff;
}

.input-group img {
  margin-right: 0.5rem;
}

.input-group input,
.form-group input {
  background: transparent;
  background-color: #fff;
  border: none;
  outline: none;
  width: 100%;
  color: #333;
}

.right-content input,
.right-content .input-group {
  width: 100%;
  box-sizing: border-box;
}

/* Submit Button */
.submit-button {
  background-color: #FF6F61; /* lighter purple */
  color: #573a3a;
  font-weight: bold;
  padding: 0.75rem;
  border-radius: 8px;
  width: 100%;
  max-width: 100%; /* limits button width on larger screens */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #FF8A65; /* darker purple */
  color: whitesmoke;
}
/* Style for the textarea */
textarea {
  width: 100%; /* Full width of the parent */
  padding: 10px; /* Padding for comfort */
  border: none; /* Remove border */
  border-radius: 4px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background color */
  resize: none; /* Prevent resizing */
  font-size: 1rem; /* Font size for readability */
  color: #333; /* Text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add slight shadow for depth */
}

/* Focus styles */
textarea:focus {
  outline: none; /* Remove default outline on focus */
  box-shadow: 0 0 5px rgba(0, 115, 255, 0.5); /* Optional: highlight on focus */
}


/* Responsive Design for Mobile */
@media (max-width: 768px) {
.box-wrapper{
  display: block;
  width: 100%;
}
.call-to-action-card{
  max-width: 90%;
}

.heading{
  font-size: 2.5rem;
}
  .content-wrapper {
    flex-direction: column; /* Stack content vertically */
  padding: 20px;
  }

  .left-content{
    max-width: 100%; /* Full width on mobile */
    width: 100%; /* Full width on mobile */
    padding:0; /* Optional: Add padding for spacing */
 
  }
  .right-content {
    max-width: 100%; /* Full width on mobile */
    width: 100%; /* Full width on mobile */
      }
}

/* Desktop Layout */
@media (min-width: 900px) {
  .left-content {
    width: 50%;
  }
  .right-content {
    width: 50%;
  }
}
.heading {
  position: relative;
}

/* Testimonials section background and overlay */
.testimonials {
  background: url("https://i.ibb.co/JRrHmhv/shanghai.jpg") center center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 50px 0 70px;
  color: #ffffff;
  position: relative;
  text-align: center;
}

.testimonials:before {
  content: "";
  background: rgba(0, 0, 0, 0.5); /* Overlay effect */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Heading styles */
.testimonials .heading h2 {
  font-size: 25px;
  font-weight: 700;
  color: #ffffff;
  z-index: 2;
  position: relative;
}

.testimonials .heading h2 span {
  color: #ffa200; /* Highlight color */
}

.testimonials p {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.7;
  color: #d1e5e7;
  margin: 20px 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

/* Carousel Styles */
.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center; /* Center carousel content */
z-index: 2;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%; /* Ensure container takes full width */
}

.carousel-item {
  flex: 0 0 100%; /* Ensure each item takes full width of the container */
  padding: 50px 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.539); /* Dark background for each slide */
  color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
}

/* Carousel images and text */
.carousel-item img {
  width: 100px;
  height: 100px;
  border: 2px solid #ffa200;
  border-radius: 50%;
  padding: 5px;
  margin: 50px 0 15px;
}

.carousel-item h3 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  margin-top: 10px;
}

.carousel-item h4 {
  font-size: 14px;
  font-weight: 300;
  color: #e2e1e1;
  margin-bottom: 20px;
}

/* Carousel Controls */
.carousel-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.control-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 30px;
  line-height: 1;
}

/* Indicators */
.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.indicator {
  width: 10px;
  height: 10px;
  background-color: #b8b7b7;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #ff0000;
}


.footer {
  
  background-color: #0f0e0e;
  padding: 1.5rem 1.5rem; /* Vertical padding */
  color: #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
   /* Ensure footer takes full width */
  margin: 0; /* Reset margin */
  box-sizing: border-box; /* Include padding in width calculation */
}

.footer h4{
  font-size:1.5rem ;
  color: #dca037;
  margin-bottom: 0.5rem;
  
}
.footer-links {
  display: flex;
  justify-content: space-between;
}

.left-footer {
  flex: 0 0 30%; /* Left footer takes 30% of the width */
  padding-right: 30px; /* Padding to separate from right footer */
  max-width: 30%;
}

.right-footer {
  flex: 1; /* Right footer takes the remaining space */
  display: flex;
  justify-content: space-between; /* Distribute space evenly */
  align-items: flex-start; /* Align items to the start */
}


/* Ensure each section in right-footer has some margin */
.footer-services, .footer-contact, .footer-social {
  margin-right: 20px; /* Space between each section */
}

/* Prevent margin on the last item to avoid extra space */
.footer-social {
  margin-right: 0; /* Remove margin from the last section */
}

.footer-contact a {
  color: white; /* Set the link color to white */
  display: flex; /* Make the link a flex container */
  align-items: center; /* Center icon and text vertically */
text-decoration: none;
line-height: 2; 
}

.footer-contact a:hover {
  color: #dca037; /* Change color on hover for better visibility */
}
.footer-contact a span {
  margin-left: 0.5rem; /* Add space between the icon and text */
}

/* Adjust icon color for white */
.footer-contact i {
  color: white; /* Ensure the icon is also white */
}

/* Ensure the color for the social media links is white */
.footer-social a {
 text-decoration: none;
  color: white; /* Set social media links to white */
}

/* Hover color for social media links */
.footer-social a:hover {
  color: #dca037; /* Change hover color for social media */
}


/* Styles for About, Services, Contact, and Social Sections */
.footer-about {
  text-align: left; /* Align text to the left */
}

.footer-services ul {
  list-style: disc;  /* Removes the default bullet points from the list */
  line-height: 2;   /* Sets the space between lines of text to 1.6 times the font size */
  padding: 0;         /* Removes default padding around the list */
  margin: 0;          /* Removes default margin around the list */

}

.footer-social .social-links {
  display: flex;
  line-height: 2; 
  gap: 1rem; /* Space between social links */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer {
    
    flex-direction: column; /* Stack footer sections vertically */
    align-items: center; /* Center align for mobile */
    text-align: center; /* Center text alignment for all footer content */
  }

  .footer-links {
    flex-direction: column; /* Stack links vertically */
    align-items: center;
     /* Center align links on mobile */
  }
  .footer-services ul {
    text-align: left;
    padding-left: 20%;
    width: 100%;
  }
  .left-footer {
    width: 100%; /* Full width on mobile */
    padding-right: 0; /* Remove padding on mobile */
        max-width: 100%;
  }
  .footer-about {
    text-align: center; /* Align text to the left */
  }
  

  .right-footer {
    width: 100%; /* Full width on mobile */
    flex-direction: column; /* Stack right footer sections vertically on mobile */
    align-items: center; /* Center align items on mobile */
     /* Center text for right footer */
  }
  .footer-contact {
   margin-right: 0;
    text-align: center; 
    align-items: center;
  }
  /* Optionally center each section in right-footer */
  .footer-services,  .footer-social {
    margin: 0;
    flex-direction: column; /* Stack right footer sections vertically on mobile */
   /* Reset margin for uniform spacing */
  }
}
