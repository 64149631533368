
  
  /* Form Styles */

  .background-image-career {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Fills the entire viewport height */
    object-fit: cover; /* Ensures the image scales correctly */
    z-index: -2; /* Sends the image behind other elements */
  }
  .career-container {
    font-family: 'Manrope' sans-serif;
    max-width: 700px;
    margin: 10px auto;
    padding: 30px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #dddddd11;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(237, 221, 221, 0.1);
  }
  
  h1{
    text-align: center;
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 0;
    /* text-shadow: 2px 2px 5px rgba(250, 248, 248, 0.881);
    -webkit-text-stroke: 0.41px rgb(253, 252, 252);  */
  }
  form label {
    display: block;
    margin-bottom: 15px;
  }
  
  form label span {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #0f0f0e;
  }
  
  form input[type="text"],
  form input[type="email"],
  form input[type="tel"],
  form input[type="file"],
  form select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #777db1;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
  }
  
  form button {
    width: 100%;
    background: #090f5a;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  form button:hover {
    background: #090f5a;
  }
  
  /* Required Field Asterisk */
  .required-label::before {
    content: "*";
    color: red;
    margin-right: 5px;
  }
  
  
  .career-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1.5rem;
  }
  
  .career-table th
 {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    color: whitesmoke;
  }
  .career-table td
 {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    
  }
  
  .career-table th {
    background-color: rgb(12, 22, 91);
    font-weight: bold;
  }
  
  .career-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  .career-table tr:nth-child(odd) {
    background-color: #f5f5f8;
  }

  
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .career-container {
      padding:5%;
         }
  
    h1 {
      font-size: 24px;
    }
  
    p {
      font-size: 14px;
    }
    .background-image-career{
     height: 130vh;
     

    }
  }
  